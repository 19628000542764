let baseUrl = "";
switch (process.env.VUE_APP_TITLE) {
    case 'test':
        baseUrl = "/api";
        break;
    case 'production':
        baseUrl = "/api";
        break;
    case 'pre':
        baseUrl = "/api";
        break;
    default:
        baseUrl = "/api";
        break;
}
// const currentDomain = window.location.protocol + "//" + window.location.host;
// console.log(currentDomain);
export default baseUrl;
// https://xrcbl.cn/api
// https://xrcbl.cn/api
// https://xrcbl.cn/api
// https://xrcbl.cn/api
//郭
//192.168.100.175:8080
//阳
//192.168.101.139:8080
//涛
//192.168.21.215:8080
//坤
//192.168.21.185:8080
//测试环境
//192.168.100.141:8080
