import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Viewer from 'v-viewer'; // 引入js
import 'viewerjs/dist/viewer.css'; // 引入css
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// 引入rem
import 'lib-flexible';
Vue.use(Viewer) // 默认配置写法
Vue.use(ElementUI);
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  // 路由守卫
  if (/www./g.test(to.path)) {
    window.open("http://" + to.path.slice(to.path.indexOf('www.')))
    next(false)
  } else if (/http:/g.test(to.path)) {
    window.open("http://" + to.path.slice(to.path.indexOf('http:')))
    next(false)
  } else if (/https:/g.test(to.path)) {
    window.open("https://" + to.path.slice(to.path.indexOf('https:')))
    next(false)
  } else {
    next()
  }
})

import MetaInfo from 'vue-meta-info';
Vue.use(MetaInfo)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
