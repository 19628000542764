import axios from "axios";
import { Loading, Message } from "element-ui";
import session from "../utils/session.js";
import baseUrl from "./constans";
// 加载ing....
const options = {
  lock: true,
  text: "Loading",
  spinner: "el-icon-loading",
  background: "rgba(0, 0, 0, 0.1)",
};
let loadingInstance;
// axios.defaults.headers = {
//   "Content-Type": "application/json;charset=utf8",
// };
// console.log("process.env.VUE_APP_API", process.env.VUE_APP_API);
axios.defaults.baseURL = baseUrl;
// 请求拦截器
axios.interceptors.request.use(
  config => {
    const token = session.get("appoint-token");
    if (token) {
      config.headers["token"] = token;
    }
    loadingInstance = Loading.service(options);
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
axios.interceptors.response.use(
  response => {
    loadingInstance.close();
    const res = response.data;
    if (res.code == 401) {
      Message.error(res.msg);
      session.remove('appoint-token');
      window.location.href = '/#/toJoin'
      session.save('typeIndex',4)
    }
    return res;
  },
  error => {
    loadingInstance.close();
    const res = error.response.data
    return Promise.reject(res);
  }
);
/* -------------------------------------------- */
export function getData(url, json = null) {
  return axios({
    url,
    method: "get",
    params: json,
    // data: json
  })
    .then(res => res)
    .catch(error => error.response);
}
export function postData(url, json) {
  return axios({
    url,
    method: "post",
    data: json,
  })
    .then(res => res)
    .catch(error => error.response);
}
export function upload(url, json) {
  return axios({
    url,
    method: "post",
    data: json,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then(res => res)
    .catch(error => error.response);
}
export function deleteJSON(url, json) {
  return axios({
    url: url,
    method: "delete",
    data: json,
  })
    .then(res => res)
    .catch(error => error.response);
}
export function putData(url, json) {
  return axios({
    url,
    method: "put",
    data: json,
  })
    .then(res => res)
    .catch(error => error.response);
}
export function downloadFile(url, json) {
  return axios({
    url,
    method: "get",
    params: json,
    withCredentials: true,
    responseType: "blob",
  })
    .then(res => res)
    .catch(error => error.response);
}
