<template>
  <div id="app">
    <Header v-if="isReload"></Header>
    <!-- ref="mainDiv" -->
    <div ref="mainDiv" v-if="isReload">
      <router-view></router-view>
    </div>
    <Fooert></Fooert>
    <!-- v-show="!$route.meta.isHideFooter" 需要隐藏footer的路由如此配置-->
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Fooert from "./components/Footer.vue";
import session from "@/utils/session";
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
export default {
  components: {
    Header,
    Fooert,
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isReload: true,
    };
  },
  methods: {
    reload() {
      this.isReload = false
      this.$nextTick(() => {
        this.isReload = true
      })
    },
    getDivHeight() {
      const screenheight = window.innerHeight;
      let sumHeight = 0
      if(this.$route.meta.isHideHeader == true && this.$route.meta.isHideHeader == 'true'){
        // this.$refs.mainDiv.style.minHeight = screenheight - 800 + "px";
        // sumHeight = screenheight - 800 + "px";
        this.$refs.mainDiv.style.minHeight = 565+ "px";
        sumHeight = 565+ "px";
      }else{
        this.$refs.mainDiv.style.minHeight = 565 + "px";
        sumHeight = 565 + "px";
      }
      session.save('sumHeight',sumHeight)
      // 704 为 头部组件高度 + 底部组件高度
      // mainDiv 为需控制高度自适应盒子，通过 ref="mainDiv" 绑定
    },
  },
  // 在 mounted 生命周期监听窗口变化并触发上文处理函数，修改高度
  mounted() {
    this.getDivHeight();
    window.addEventListener("resize", this.getDivHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.getDivHeight, false);
  },
};
</script>
<style lang="less">
html,
body {
  height: 100%;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  color: #333333;
}
.titleDot {
	position: relative;
  font-size: 20px;
	&::after {
		content: "";
		left: -10px;
		bottom: 2px;
		width: 3px;
		height: 20px;
		background: #484CAB;
		opacity: .6;
		position: absolute;
	}
}
.rigtopTit{
  padding-top: 50px;
  position: relative;
  font-size: 18px;
  color: #333;
  &::after {
		content: "";
		left: 90px;
    right: 0;
		bottom: 11px;
		width: 873px;
		height:1px;
		background: #D2D2D2;
		position: absolute;
	}
}
.rigtopTits{
  position: relative;
  &::after {
		content: "";
		left: 90px;
    right: 0;
		bottom: 11px;
		width: 92%;
		height:0px;
		// background: #D2D2D2;
    border: 1px dashed #D2D2D2;
		position: absolute;
	}
}
.rigtopTitTs{
  position: relative;
  &::after {
		content: "";
		left:180px;
    right: 0;
		bottom: 11px;
		width: 85%;
		height:0px;
		// background: #D2D2D2;
    border: 1px dashed #D2D2D2;
		position: absolute;
	}
}
</style>
