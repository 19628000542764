//api.js
import { deleteJSON, getData, postData, putData } from "./axios";
/* 登录/注册 */
export const loginApi = {
  // login(data) {
  //   return postData("/api/login", data);
  // },
};

//其它接口
export const publicApi = {
  // 公共产品分类*
  commonCategory(params){
    return getData("/common/category",params)
  },
	agreemen(){
	  return getData('/page?id=1')
	},
  // 公共地区列表*
  commonArea(params){
    return getData("/common/area",params)
  },
  // 首页*
  apiIndex(params){
    return getData("/index",params)
  },
  // 产品列表*
  apiProduct(params){
    return getData("/product",params)
  },
  // 产品详情*
  apiProductDetail(id){
    return getData(`/product/${id}`)
  },
  // 产品收藏*
  collectssa(id){
    return getData(`/product/collect/${id}`)
  },
  // 产品取消收藏*
  collectCall(id){
    return getData(`/product/cancel/${id}`)
  },
  // 推荐商家*
  companyHome(params){
    return getData('/company/home',params)
  },
  // 商家列表*
  companys(params){
    return getData('/company',params)
  },
  // 商家详情*
  companyDetail(id){
    return getData(`/company/${id}`)
  },
  // 行业动态列表*
  articleList(params){
    return getData('/article',params)
  },
  // 行业动态详情*
  articles(id,params){
    return getData(`/article/${id}`,params)
  },
  // 展会信息首页*
  exhibitionHome(params){
    return getData('/exhibition/home',params)
  },
  // 展会信息列表*
  exhibition(params){
    return getData('/exhibition',params)
  },
  // 展会信息详情*
  exhibitionDetail(id){
    return getData(`/exhibition/${id}`)
  },
  // 意见反馈*
  feedback(data){
    return postData('/feedback',data)
  },
  // 获取图形验证码*
  commonCaptcha(params){
    return getData('/common/captcha',params)
  },
  // 会员登陆*
  userLogin(data){
    return postData('/user/login',data)
  },
  //系统设置*
  system(params){
    return getData('/common/system',params)
  },
  // 注册加盟*
  register(data){
    return postData('/user/register',data)
  },
  // 忘记密码*
  forgetpwd(data){
    return postData('/user/forgetpwd',data)
  },
  // 获取验证码*
  smsSend(data){
    return postData('/sms/send',data)
  },
  // 退出登录*
  logout(params){
    return getData('/user/logout',params)
  },
  // 个人中心*(部分还未调用)
  apiUser(params){
    return getData('/user',params)
  },
  // 修改资料*
  profile(data){
    return postData('/user/profile',data)
  },
  // 重置密码*
  resetpwd(data){
    return postData('/user/resetpwd',data)
  },
  // 站内信列表*
  notify(params){
    return getData('/user/notify',params)
  },
  // 站内信详情*
  notifyDetails(id){
    return getData(`/user/notify/${id}`)
  },
  // 站内信标记已读*
  notifyRead(ids){
    return getData(`user/notify/read/${ids}`)
  },
  // 站内信全部已读*
  readAll(params){
    return getData('/user/notify/read_all',params)
  },
  // 站内信删除选中*
  notifyDelete(ids){
    return deleteJSON(`/user/notify/${ids}`)
  },
  // 站内信清空*
  clear(params){
    return deleteJSON('/user/notify/clear',params)
  },
  // 收藏列表*
  collect(params){
    return getData('/user/collect',params)
  },
  // 收藏删除选中*
  collectDelete(ids){
    return deleteJSON(`/user/collect/${ids}`)
  },
  // 收藏清空*
  collectClear(params){
    return deleteJSON('/user/collect/clear',params)
  },
  //信息认证*
  companyRenz(data){
    return postData('/user/company',data)
  },
  // 信息认证详情*
  companyRenzDetil(params){
    return getData('/user/company',params)
  },
  // 产品列表*
  productLists(params){
    return getData('/user/product',params)
  },
  // 产品详情*
  productDetails(id){
    return getData(`/user/product/${id}`)
  },
  // 产品添加*
  productAdd(data){
    return postData('/user/product',data)
  },
  // 产品修改*
  productPut(id,data) {
    return putData(`/user/product/${id}`,data)
  },
  // 产品删除选中*
  productDeletes(ids){
    return deleteJSON(`/user/product/${ids}`)
  },
  // 新增栏目***
  commonClassType(params){
    return getData('/common/class_type',params)
  },
}