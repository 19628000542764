<template>
  <div>
    <div class="heTop">
      <div class="topLeft">你好，欢迎来到中国隔音玻璃网！</div>
      <div class="topRig">
        <div
          class="topRigLog"
          v-if="is_login == false || is_login == 'false'"
        >
          <span @click.stop="centres" style="padding-right: 20px; color: #484cab">请登录</span>
          <span  @click.stop="sginClick">免费注册</span>
        </div>
        <div class="topRigLog"  @click.stop="loginRegs" v-else>
          <span style="padding-right: 20px; color: #484cab">昵称：{{infoObj.realname}}</span>
        </div>
        <div  @click.stop="loginClick" v-if="is_login == false || is_login == 'false'">商家入驻申请 | 平台客服</div>
        <div v-else @click.stop="dropOut">退出登录</div>
      </div>
    </div>
    <div v-show="!$route.meta.isHideHeader" class="heCon">
      <div class="conTop">
        <div class="conTopLogo" @click.stop="indexClick">
          <img
            src="@/assets/img/logo.png"
            alt=""
          />
        </div>
        <div style="width: 688px">
          <el-input placeholder="请输入内容" v-model="input2">
            <template slot="append">
              <div @click.stop="search">搜索</div>
            </template>
          </el-input>
          <div class="historyList">
            <div class="hislist" v-for="(item, index) in list" :key="index" @click="searLis(item.name)">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="conBom">
        <div class="dropdown">
          <div class="bomLeft">
            <div>全部商品分类</div>
            <i class="el-icon-arrow-right"></i>
          </div>
          <!-- 下拉菜单内容 -->
          <div class="dropdown-content">
            <div
              class="dropdown-menu"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="menuItem" @click="searLis(item.name)">
                <div>{{ item.name }}</div>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="menusList">
          <div
            v-for="(item, index) in mList"
            :key="index"
            :class="item.id == menuType ? 'menuActive' : 'menu'"
            @click.stop="menuClick(item)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="menuRight">
          <img src="../assets/img/phoneIcon.png" alt="" />
          <div>{{phone}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { publicApi } from "@/api/api";
import session from "@/utils/session";
export default {
  data() {
    return {
      input2: "",
      list: [],
      mList: [
        {
          title: "首  页",
          path: "/",
          name: "home",
          id: 1,
        },
        {
          title: "产品中心",
          path: "/productCenter",
          name: "productCenter",
          id: 2,
        },
        {
          title: "推荐商家",
          path: "/merchant",
          name: "merchant",
          id: 3,
        },
        {
          title: "招商加盟",
          path: "/toJoin",
          name: "toJoin",
          id: 4,
        },
        {
          title: "行业动态",
          path: "/industry",
          name: "industry",
          id: 5,
        },
        {
          title: "展会信息",
          path: "/exhibition",
          name: "exhibition",
          id: 6,
        },
        {
          title: "联系我们",
          path: "/contactUs",
          name: "contactUs",
          id: 7,
        },
      ],
      menuType: 1,
      is_login: false,
      infoObj: {},
    };
  },
  created() {
    this.menuType = session.get('typeIndex') ?session.get('typeIndex') :1
    this.commonCategory();
    this.system();
  },
  methods: {
    indexClick(){
      this.$router.push('/').catch(err => {
        console.log('跳转异常：',err)
      })
      this.menuType = 1
      this.reload()
    },
    // 搜索
    search(){
      this.$router.push("/seek").catch((err) => {
        console.log("跳转异常：", err);
      });
      session.save('keyword',this.input2)
    },
    // 搜索历史
    searLis(e){
      this.$router.push("/seek").catch((err) => {
        console.log("跳转异常：", err);
      });
      session.save('keyword',e)
    },
    menuClick(e) {
      this.menuType = e.id;
      session.save('typeIndex',e.id)
      if(e.title == '招商加盟' && this.is_login == true || this.is_login == 'true'){
        this.$router.push("/myCentre").catch((err) => {
          console.log("跳转异常：", err);
        });
      }else{
        this.$router.push(e.path).catch((err) => {
          console.log("跳转异常：", err);
        });
      }
    },
    // 跳转到登录页
    centres() {
      this.$router.push("/toJoin").catch((err) => {
        console.log("跳转异常：", err);
      });
      this.menuType = 4
    },
    // 注册
    sginClick(){
      this.$router.push('/signIn').catch((err) => {
        console.log("跳转异常：", err);
      });
      this.menuType = 4
      this.reload()
    },
    // 请求产品列表
    commonCategory() {
      publicApi.commonCategory().then((res) => {
        if (res.code == 200) {
          this.list = res.data;
        }
      });
    },
    // 系统设置
    system() {
      publicApi.system().then((res) => {
        if (res.code == 200) {
          this.is_login = res.data.is_login;
          this.phone = res.data.phone
          if (this.is_login == true || this.is_login == "true") {
            this.apiUser()
          }
        }
      });
    },
    // 个人资料
    apiUser() {
      publicApi.apiUser().then((res) => {
        if (res.code == 200) {
          this.infoObj = res.data;
        }
      });
    },
    // 跳转个人中心
    loginRegs(){
      this.$router.push("/myCentre").catch((err) => {
        console.log("跳转异常：", err);
      });
    },
    // 退出登录
    dropOut(){
      publicApi.logout().then(res => {
        if(res.code == 200){
          this.$message(res.msg);
          this.system();
        }
      })
    },
    // 登录跳转
    loginClick(){
      this.$router.push('/toJoin').catch((err) => {
        console.log("跳转异常：", err);
      });
      this.menuType = 4
      this.reload()
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input-group__append {
  background: #114493;
  color: #fff;
}
.heTop {
  cursor: pointer;
  height: 39px;
  background: #d2d2d2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 222px;
  font-size: 14px;
  .topRig {
    display: flex;
    align-items: center;
    .topRigLog {
      padding-right: 25px;
    }
  }
}
.heCon {
  min-height: 228px;
  background: #fff;
  cursor:pointer;
  .conTop {

    display: flex;
    align-items: center;
    padding: 52px 228px 42px 228px;
    .conTopLogo {
      padding-right: 189px;
      img{
         height: 57px
      }
    }
    .historyList {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 0rpx;
      padding-top: 10px;
      .hislist {
        color: #114493;
        font-size: 16px;
      }
    }
  }
  .conBom {
    padding: 0 228px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dropdown {
      .bomLeft {
        width: 272px;
        height: 64px;
        background: #114493;
        border-radius: 24px 24px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #fff;
        cursor: pointer;
        position: relative;
        .el-icon-arrow-right {
          padding-left: 10px;
          padding-top: 5px;
        }
      }
      .dropdown-content {
        // 定位显示局域
        position: absolute;
        visibility: hidden; // 隐藏
        opacity: 0; // 隐藏
        transition: all 0.6s ease-in-out;
        width: 272px;
        z-index: 999;
        .dropdown-menu {
          color: black;
          background: #fff;
          .menuItem {
            width: 100%; // 自适应宽度
            white-space: nowrap;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: black;
            cursor: pointer;
            .el-icon-arrow-right {
              padding-left: 50px;
            }
            &:hover {
              background-color: #2d68c4;
              color: #fff;
            }
          }
        }
      }
      &:hover .dropdown-content {
        visibility: visible;
        opacity: 1;
      }
    }
    .menusList {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 50px;
      cursor: pointer;
      .menu {
        text-align: center;
        font-size: 20px;
        color: #333333;
      }
      .menuActive {
        text-align: center;
        font-size: 20px;
        color: #114493;
        position: relative;
        &::after {
          content: "";
          left: 0px;
          bottom: -10px;
          width: 83px;
          height: 4px;
          background: #114493;
          position: absolute;
        }
      }
    }
    .menuRight {
      display: flex;
      align-items: center;
      font-size: 30px;
      color: #114493;
      img {
        width: 33px;
        height: 33px;
      }
      div {
        padding-left: 10px;
      }
    }
  }
}
</style>