import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [
  // meta:{isHideFooter:true} 需要隐藏footer的路由如此配置
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home'),
  },
  {
    path: '/productCenter',
    name: 'productCenter',
    component: () => import('@/views/productCenter.vue'),
  },
  {
    path: '/detailsGoods',
    name: 'detailsGoods',
    component: () => import('@/views/detailsGoods.vue'),
  },
  {
    path: '/merchant',
    name: 'merchant',
    component: () => import('@/views/merchant.vue'),
  },
  {
    path:'/tevenMore',
    name:'tevenMore',
    component:() => import('@/views/merchanTevenMore.vue')
  },
  {
    path: '/toJoin',
    name: 'toJoin',
    component: () => import('@/views/toJoin.vue'),
  },
  {
    path: '/industry',
    name: 'industry',
    component: () => import('@/views/industry.vue'),
  },
  {
    path: '/exhibition',
    name: 'exhibition',
    component: () => import('@/views/exhibition.vue'),
  },
  {
    path: '/moreList',
    name: 'moreList',
    component: () => import('@/views/moreList.vue'),
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('@/views/contactUs.vue')
  },
  {
    path: '/merchantDetails',
    name: 'merchantDetails',
    component: () => import('@/views/merchantDetails.vue'),
  },
  {
    path:'/retrievePass',
    name:'retrievePass',
    component:() => import('@/views/retrievePass.vue')
  },
  {
    path:'/signIn',
    name:'signIn',
    component:() => import('@/views/signIn.vue')
  },
  {
    path:'/newsDetails',
    name:'newsDetails',
    component:() => import('@/views/newsDetails.vue')
  },
  {
    path:'/exhibitonDetails',
    name:'exhibitonDetails',
    component:() => import('@/views/exhibitonDetails.vue')
  },
  {
    path:'/seek',
    name:'seek',
    component:() => import('@/views/seek.vue'),
    meta:{isHideHeader:true},
  },
  {
    path:'/myCentre',
    component:() => import('@/views/myCentre/index.vue'),
    meta:{isHideHeader:true},
    children:[
      {
        path: '/',
        name: 'memberCentre',
        redirect:'/myCentre',
        component: () => import('@/views/myCentre/memberCentre/index.vue'),
        meta:{isHideHeader:true},
        children:[
          {
            path: '/',
            name: 'account',
            component: () => import('@/views/myCentre/memberCentre/account.vue'),
            meta:{isHideHeader:true},
          },
          {
            path: '/letter',
            name: 'letter',
            component: () => import('@/views/myCentre/memberCentre/letter.vue'),
            meta:{isHideHeader:true},
          },
          {
            path: '/collect',
            name: 'collect',
            component: () => import('@/views/myCentre/memberCentre/collect.vue'),
            meta:{isHideHeader:true},
          },
          {
            path: '/editProfile',
            name:'editProfile',
            component: () => import('@/views/myCentre/memberCentre/editProfile.vue'),
            meta:{isHideHeader:true},
          },
          {
            path: '/accountDetails',
            name: 'accountDetails',
            component: () => import('@/views/myCentre/memberCentre/accountDetails.vue'),
            meta:{isHideHeader:true},
          },
          {
            path: '/letterDetail',
            name: 'letterDetail',
            component: () => import('@/views/myCentre/memberCentre/letterDetail.vue'),
            meta:{isHideHeader:true},
          },
        ]
      },
      {
        path: '/information',
        name: 'information',
        component: () => import('@/views/myCentre/information/index.vue'),
        meta:{isHideHeader:true},
      },
      {
        path: '/modified',
        name: 'modified',
        component: () => import('@/views/myCentre/information/modified.vue'),
        meta:{isHideHeader:true},
      },
      {
        path: '/acCenter',
        name: 'acCenter',
        component: () => import('@/views/myCentre/acCenter/index.vue'),
        meta:{isHideHeader:true},
      },
      {
        path:'/approveTips',
        name:'approveTips',
        component:() => import('@/views/myCentre/acCenter/approveTips.vue'),
        meta:{isHideHeader:true},
      },
      {
        path:'/agreement',
        name:'agreement',
        component:() => import('@/views/myCentre/acCenter/agreement.vue'),
        meta:{isHideHeader:true},
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
