<template>
  <div class="footers">
    <div class="footerTop">
      <div class="forms">
        <div class="menusList">
          <div class="menu" v-for="(item, index) in mList" :key="index">
            {{ item }}
          </div>
        </div>
        <div class="formsBom">
          <div class="liuyan" style="">留言</div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div class="inputs">
              <el-input
                placeholder="请输入你的名字"
                v-model="form.realname"
              ></el-input>
            </div>
            <div class="inputss">
              <el-input
                placeholder="请输入你的电话"
                v-model="form.mobile"
              ></el-input>
            </div>
            <div class="inputsss">
              <el-input
                placeholder="请输入留言内容"
                v-model="form.content"
              ></el-input>
            </div>
            <el-input
              placeholder="请输入验证码"
              v-model="form.captcha"
            ></el-input>
            <img
              style=""
              :src="checkCodeUrl"
              alt=""
              @click.stop="changeCheckCode"
            />
          </div>
          <div @click.stop="submits" class="sbmit">提交留言</div>
        </div>
      </div>
      <div class="formsRig">
        <img :src="objet.qrcode" style="" alt="" />
        <div class="rigTisi">
          <div>扫码关注微信</div>
          <div class="shushi">随时掌握信息</div>
        </div>
      </div>
    </div>
    <div class="footerBom">{{ objet.copyright }}-{{ objet.beian }}</div>
  </div>
</template>

<script>
import { publicApi } from "@/api/api";
export default {
  data() {
    return {
      mList: [
        "首  页",
        "产品中心",
        "推荐商家",
        "招商加盟",
        "行业动态",
        "展会信息",
        "联系我们",
      ],
      form: {
        realname: "",
        mobile: "",
        content: "",
        captcha: "",
        telephone: "",
        email: "",
        address: "",
        gender: "",
      },
      objet: {},
      checkCodeUrl: "",
    };
  },
  created() {
    this.system();
    this.changeCheckCode();
  },
  methods: {
    system() {
      publicApi.system().then((res) => {
        if (res.code == 200) {
          this.objet = res.data;
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    // 获取验证码
    changeCheckCode() {
      const currentDomain = window.location.protocol + "//" + window.location.host;
      this.checkCodeUrl =`${currentDomain}/api/common/captcha?` + new Date().getTime();
    },
    submits() {
      publicApi.feedback(this.form).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.form.content = "";
          this.form.realname = "";
          this.form.mobile = "";
          this.form.captcha = "";
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  width: 155px !important;
  border-radius: 50px !important;
  background: #fff !important;
  height: 20px;
}
/deep/.el-color-picker__icon,
.el-input,
.el-textarea {
  display: inline-block;
  width: auto !important;
}
.footers {
  height: 244px;
  color: #fff;
  .footerTop {
    height: 177px;
    padding: 45px 333px;
    background: #114493;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .forms {
      .menusList {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 50px;
        .menu {
          font-size: 20px;
          color: #fff;
        }
      }
      .formsBom {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 34px;
        .liuyan {
          font-size: 25px;
        }
        img {
          width: 100px;
          height: 45px;
          padding-left: 10px;
          padding-right: 10px;
        }
        .inputs {
          padding-left: 10px;
        }
        .inputss {
          padding: 0 10px;
        }
        .inputsss {
          padding-right: 10px;
        }
        .sbmit {
          width: 80px;
          height: 20px;
          background: #7bccf6;
          border-radius: 10px;
          text-align: center;
          line-height: 20px;
          border-radius: 20px;
          font-size: 12px;
          color: #fff;
        }
      }
    }
    .formsRig {
      display: flex;
      align-items: center;
      img {
        width: 112px;
        height: 112px;
      }
      .rigTisi {
        font-size: 24px;
        line-height: 40px;
        padding-left: 15px;
        text-align: center;
        .shushi {
          font-size: 20px;
        }
      }
    }
  }
  .footerBom {
    height: 67px;
    background: #333333;
    text-align: center;
    line-height: 67px;
    font-size: 12px;
  }
}
</style>